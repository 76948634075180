import React from 'react';
import _ from 'lodash';
import styled, { withTheme } from 'styled-components';

import loadable from '@loadable/component';
import { capfl } from 'common/utils';
import { Headline4 } from 'common/components/styleguide/Headline';
import { List, ListItem } from 'common/components/styleguide/List';
import { Block, BlockFlex } from 'common/components/styleguide/grid/Block';
import { Container, Row, Column } from 'common/components/styleguide/grid';

const Newsletter = loadable(
  () => import('common/blocks/functional/newsletter'),
  { ssr: false }
);
const Icon = loadable(() => import('common/components/styleguide/Icon'));
const Link = loadable(() => import('common/components/_base/RouteLink'));

import {
  ICON_LOGO_FACEBOOK,
  ICON_LOGO_GOOGLE,
  ICON_LOGO_INSTAGRAM
} from 'common/constants/IconConstants';

const CHUNK_CATEGORIES_FROM = 10;

const S = {};
S.Link = styled(Link)`
  margin-right: 10px;
  font-size: 0.9em;
`;

const FooterVariant2 = ({ t, theme, logo, data, categories, pages }) => {
  const categoriesIndex = _.get(theme, 'footer.categoryIndex', null);
  const footerCategories = _.isNumber(categoriesIndex)
    ? _.get(categories, [categoriesIndex, 'children'], [])
    : categories;
  const footerCategoriesLength = footerCategories.length;
  const chunkedFooterCategories =
    footerCategoriesLength > CHUNK_CATEGORIES_FROM
      ? _.chunk(footerCategories, footerCategoriesLength / 2)
      : [footerCategories];
  return (
    <Block
      backgroundColor="backgroundSecondary"
      marginConfig={{ top: true }}
      padding="50px 0"
      borderConfig={{}}
      customStyle={_.get(theme, 'footer.customStyle')}
    >
      <Container>
        <Row>
          <Column size={{ xs: 12, md: 3 }}>
            <Headline4 color={'primary'}>{data.address.name}</Headline4>
            <List reset>
              {_.map(pages, page => {
                return (
                  <ListItem key={'footer-page-item-' + page.id}>
                    <Link
                      customStyle={'font-size: .9em;'}
                      linkType={'text'}
                      route="static"
                      params={{ urlTitle: page.urlTitle }}
                    >
                      {page.title}
                    </Link>
                  </ListItem>
                );
              })}
            </List>
          </Column>
          <Column size={{ xs: 12, md: 6 }}>
            <Headline4 color={'primary'}>{capfl(t('categories'))}</Headline4>
            <Row>
              {_.map(chunkedFooterCategories, (categoriesChunk, index) => (
                <Column
                  key={'footer-categories-chunk-' + index}
                  size={{
                    xs: 12,
                    md: chunkedFooterCategories.length > 1 ? 6 : 12
                  }}
                >
                  <List reset className="categories">
                    {_.map(categoriesChunk, category => {
                      return (
                        <ListItem key={'footer-category-item-' + category.id}>
                          <Link
                            customStyle={'font-size: .9em;'}
                            linkType={'text'}
                            route="category"
                            params={{ id: category.urlName }}
                          >
                            {category.name}
                          </Link>
                        </ListItem>
                      );
                    })}
                  </List>
                </Column>
              ))}
            </Row>
          </Column>
          <Column size={{ xs: 12, md: 3 }}>
            <Headline4 color={'primary'}>{capfl(t('newsletter'))}</Headline4>
            <Newsletter config={{ title: false }} />
            <BlockFlex
              flexConfig={{
                flexDirection: 'row',
                alignItems: 'center'
                //justifyContent: 'space-between'
              }}
              margin="30px 0 0 0"
            >
              {data.facebook.active && (
                <S.Link
                  linkType={'facebook'}
                  rel="nofollow"
                  href={data.facebook.link}
                  target="_blank"
                >
                  <Icon
                    className={ICON_LOGO_FACEBOOK}
                    title={data.facebook.name}
                  />
                </S.Link>
              )}
              {data.instagram.active && (
                <S.Link
                  linkType={'instagram'}
                  rel="nofollow"
                  href={data.instagram.link}
                  target="_blank"
                >
                  <Icon
                    className={ICON_LOGO_INSTAGRAM}
                    title={data.instagram.name}
                  />
                </S.Link>
              )}
              {data.google.active && (
                <S.Link
                  linkType={'google'}
                  rel="nofollow"
                  href={data.google.link}
                  target="_blank"
                >
                  <Icon className={ICON_LOGO_GOOGLE} title={data.google.name} />
                </S.Link>
              )}
              {data.mail && (
                <S.Link
                  linkType={'primary'}
                  rel="nofollow"
                  href={'mailto:' + data.mail}
                >
                  {data.mail}
                </S.Link>
              )}
              {data.phone && (
                <S.Link
                  linkType={'primary'}
                  rel="nofollow"
                  href={'tel:' + data.phone}
                >
                  {data.phone}
                </S.Link>
              )}
            </BlockFlex>
          </Column>
        </Row>
      </Container>
    </Block>
  );
};

export default React.memo(withTheme(FooterVariant2), true);
