import React from 'react';
import _ from 'lodash';
import { get, map } from 'lodash';

import loadable from '@loadable/component';
import { capfl, getLogoPath } from 'common/utils';
import { Headline3 } from 'common/components/styleguide/Headline';
import { List, ListItem } from 'common/components/styleguide/List';
import { Block, BlockFlex } from 'common/components/styleguide/grid/Block';
import { Container, Row, Column } from 'common/components/styleguide/grid';

const TextIcon = loadable(() =>
  import('common/components/styleguide/TextIcon')
);
const Link = loadable(() => import('common/components/_base/RouteLink'));
const ButtonOutline = loadable(() =>
  import('common/components/styleguide/ButtonOutline')
);
const Image = loadable(() => import('common/components/_base/Image'));

import {
  ICON_LOCATION,
  ICON_MAIL,
  ICON_PHONE,
  ICON_LOGO_FACEBOOK,
  ICON_LOGO_GOOGLE,
  ICON_LOGO_INSTAGRAM
} from 'common/constants/IconConstants';

const FooterVariant1 = ({ t, logo, data, categories, pages }) => {
  return (
    <Block
      backgroundColor="bacgroundSecondary"
      marginConfig={{bottom:true}}
      borderConfig={{top:true}}
    >
      <Container>
        <Row>
          <Column size={{ xs: 12, md: 5 }}>
            <Block
              backgroundColor={'transparent'}
              marginConfig={{}}
              paddingConfig={{}}
              customStyle={'text-align:center;'}
            >
              <Image
                path={getLogoPath(logo)}
                srcSet={{
                  '780px': '140x40.png',
                  '1240px': '210x60.png'
                }}
                size={'210x60.png'}
                alt={
                  get(logo, 'description', false) ? logo.description : 'logo'
                }
                lazyload={false}
              />
            </Block>

            {data.description && (
              <p style={{ textAlign: 'justify' }}>{data.description}</p>
            )}

            <Block
              backgroundColor={'transparent'}
              marginConfig={{ top: true, bottom: true }}
            >
              <Row>
                <Column size={{ xs: 12, md: data.mail ? 6 : 12 }}>
                  {_.get(data, 'phone') && (
                    <ButtonOutline
                      component={'a'}
                      fullWidth={true}
                      buttonSize={'small'}
                      buttonType={'text'}
                      href={'tel:' + _.get(data, 'phone')}
                    >
                      <TextIcon iconClassName={ICON_PHONE}>
                        {_.get(data, 'phone')}
                      </TextIcon>
                    </ButtonOutline>
                  )}
                </Column>
                <Column size={{ xs: 12, md: _.get(data, 'phone') ? 6 : 12 }}>
                  {_.get(data, 'mail') && (
                    <ButtonOutline
                      component={'a'}
                      fullWidth={true}
                      buttonSize={'small'}
                      buttonType={'text'}
                      href={'mailto:' + _.get(data, 'mail')}
                    >
                      <TextIcon iconClassName={ICON_MAIL}>{_.get(data, 'mail')}</TextIcon>
                    </ButtonOutline>
                  )}
                </Column>
              </Row>
            </Block>

            <Block
              marginConfig={{}}
              paddingConfig={{}}
              customStyle={'line-height:1.5em;'}
            >
              <Row>
                <Column size={{ xs: 12, md: 6 }}>
                  <Block marginConfig={{ bottom: true }} paddingConfig={{}}>
                    <Headline3>{capfl(t('address'))}</Headline3>
                    {data.address.name}
                    <br />
                    {data.address.street}
                    <br />
                    {data.address.zip}, {data.address.city}
                    <br />
                    {data.address.country}
                  </Block>
                </Column>
                <Column size={{ xs: 12, md: 6 }}>
                  <BlockFlex
                    flexConfig={{ flexDirection: 'column' }}
                    marginConfig={{ bottom: true }}
                  >
                    {data.facebook.active && (
                      <Link
                        linkType={'facebook'}
                        rel="nofollow"
                        href={data.facebook.link}
                        target="_blank"
                      >
                        <TextIcon iconClassName={ICON_LOGO_FACEBOOK}>
                          {data.facebook.name}
                        </TextIcon>
                      </Link>
                    )}
                    {data.instagram.active && (
                      <Link
                        linkType={'instagram'}
                        rel="nofollow"
                        href={data.instagram.link}
                        target="_blank"
                      >
                        <TextIcon iconClassName={ICON_LOGO_INSTAGRAM}>
                          {data.instagram.name}
                        </TextIcon>
                      </Link>
                    )}
                    {data.google.active && (
                      <Link
                        linkType={'google'}
                        rel="nofollow"
                        href={data.google.link}
                        target="_blank"
                      >
                        <TextIcon iconClassName={ICON_LOGO_GOOGLE}>
                          {data.google.name}
                        </TextIcon>
                      </Link>
                    )}
                    {data.map.active && (
                      <Link
                        linkType={'text'}
                        rel="nofollow"
                        href={data.map.link}
                        target="_blank"
                      >
                        <TextIcon iconClassName={ICON_LOCATION}>
                          {capfl(data.map.name)}
                        </TextIcon>
                      </Link>
                    )}
                  </BlockFlex>
                </Column>
              </Row>
            </Block>
          </Column>
          <Column size={{ xs: 12, md: 1 }}></Column>
          <Column size={{ xs: 6, md: 3 }}>
            <Headline3>{capfl(t('categories'))}</Headline3>
            <List reset>
              {map(categories, category => {
                return (
                  <ListItem key={'footer-category-item-' + category.id}>
                    <Link
                      customStyle={'font-size: .9em;'}
                      linkType={'gray'}
                      route="category"
                      params={{ id: category.urlName }}
                    >
                      {category.name}
                    </Link>
                  </ListItem>
                );
              })}
            </List>
          </Column>
          <Column size={{ xs: 6, md: 3 }}>
            <Headline3>{capfl(t('pages'))}</Headline3>
            <List reset>
              {map(pages, page => {
                return (
                  <ListItem key={'footer-page-item-' + page.id}>
                    <Link
                      customStyle={'font-size: .9em;'}
                      linkType={'gray'}
                      route="static"
                      params={{ urlTitle: page.urlTitle }}
                    >
                      {page.title}
                    </Link>
                  </ListItem>
                );
              })}
            </List>
          </Column>
        </Row>
      </Container>
    </Block>
  );
};

export default React.memo(FooterVariant1, true);
